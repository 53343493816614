<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button
        class="right"
        style="width: 88px; height: 36px; margin: 10px 0;"
        type="primary"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <TitleModule :title="'设备信息'"></TitleModule>
      <info-table
        :tableData="equipmentInfo"
        :wrapperStyle="{ border: 0 }"
        :num="3"
        :dataValue="equipmentInfoVal"
        :valueStyle="{ width: 'calc(100% - 100px)', 'text-align': 'left' }"
        :nameStyle="{
          background: '#fff',
          width: '100px',
          'text-align': 'right',
        }"
      ></info-table>
      <TitleModule :title="'故障报警信息'"></TitleModule>
      <info-table
        :tableData="alertInfo"
        :dataValue="equipmentInfoVal"
        :wrapperStyle="{ border: 0 }"
        :valueStyle="{ width: 'calc(100% - 100px)', 'text-align': 'left' }"
        :num="3"
        :nameStyle="{
          background: '#fff',
          width: '100px',
          'text-align': 'right',
        }"
      ></info-table>
      <TitleModule :title="'处理进度'"></TitleModule>
      <info-table
        :tableData="processInfo"
        :dataValue="processing"
        :wrapperStyle="{ border: 0 }"
        :valueStyle="{ width: 'calc(100% - 100px)', 'text-align': 'left' }"
        :num="3"
        :nameStyle="{
          background: '#fff',
          width: '100px',
          'text-align': 'right',
        }"
      >
        <template
          v-slot:[val.valueKey]
          v-for="(val, ind) in equipmentInfoVal.areaFaultInfoList"
        >
          <div class="reason" :key="ind" :title="val.faultReason">
            {{ val.faultReason }}
          </div>
        </template>
        <template
          v-slot:[val.timeKey]
          v-for="(val, ind) in equipmentInfoVal.areaFaultInfoList"
        >
          <div
            v-if="val.faultInfoStatus != 3"
            class="reason"
            :key="ind"
            :title="val.createdTime"
          >
            {{ val.createdTime }}
          </div>
        </template>
      </info-table>
    </div>
  </div>
</template>
<script>
import infoTable from "@/components/infoTable/infoTable";
import TitleModule from "@/components/titleModule/titleModule";
export default {
  name: "hello",
  data() {
    return {
      processInfo: [
        {
          name: "当前状态：",
          key: "faultInfoStatus",
          format: (val) => {
            if (val == 2) {
              return "挂起";
            } else if (val == 3) {
              return "已完成";
            } else {
              return " ";
            }
          },
        },
        {
          name: "维修人员：",
          key: "brandContact",
          col: 2,
        },
        {
          name: "分配时间：",
          key: "assignTime",
        },
        {
          name: "结束时间：",
          key: "endTime",
        },
        {
          name: "",
          key: "",
        },
      ],
      equipmentInfoVal: {},
      alarmInfoVal: {},
      processing: {},
      equipmentInfo: [
        {
          name: "道路名称：",
          key: "parkName",
        },
        {
          name: "设备名称：",
          key: "equName",
          format: (data) => {
            return data;
          },
        },
        {
          name: "设备编码：",
          key: "equCode",
        },
        {
          name: "设备类型：",
          key: "equParentType",
          format: (data) => {
            if (data == 1) {
              return "PDA设备";
            } else if (data == 2) {
              return "MS设备";
            } else if (data == 3) {
              return "枪机-半封闭";
            } else if (data == 4) {
              return "枪球机-平行";
            } else if (data == 5) {
              return "违停球";
            } else if (data == 6) {
              return "垂停设备";
            } else if (data == 7) {
              return "平行多枪设备";
            } else if (data == 8) {
              return "中位视频设备";
            } else {
              return " ";
            }
          },
        },
        {
          name: "设备子类型：",
          key: "equType",
          format: (data) => {
            if (data == 1) {
              return "PDA设备";
            } else if (data == 2) {
              return "MS设备";
            } else if (data == 3) {
              return "枪机-半封闭";
            } else if (data == 4) {
              return "枪球机-平行";
            } else if (data == 5) {
              return "违停球";
            } else if (data == 6) {
              return "垂停设备";
            } else if (data == 7) {
              return "平行多枪设备";
            } else if (data == 8) {
              return "中位视频设备";
            } else {
              return " ";
            }
          },
        },
        {
          name: "设备品牌：",
          key: "brandName",
        },
        {
          name: "联系人：",
          key: "brandContact",
        },
        {
          name: "电子邮箱：",
          key: "brandEmail",
        },
        {
          name: "联系电话：",
          key: "brandPhone",
        },
        {
          name: "关联泊位：",
          key: "equBerthCode",
          col: 3,
        },
      ],
      alertInfo: [
        {
          name: "报警类型：",
          key: "faultType",
        },
        {
          name: "报警时间：",
          key: "faultTime",
        },
        {
          name: "报修人员：",
          key: "reportPeopleName",
        },
      ],
      formInline: {
        Repair: "1",
      },
    };
  },
  methods: {
    reasonHandle(data) {
      data.forEach((v, ind) => {
        v.valueKey = "faultReason" + ind;
        v.timeKey = v.createdTime + ind;
        if (v.faultInfoStatus == 3) {
          this.processInfo.push({
            name: "故障原因：",
            key: v.valueKey,
            col: 1,
          });
        } else {
          this.processInfo.push(
            {
              name: "挂起原因：",
              key: v.valueKey,
              col: 1,
            },
            {
              name: "操作时间：",
              key: v.timeKey,
              col: 2,
            }
          );
        }
      });
    },
    queryDetail() {
      let url = "/acb/2.0/areaEquFault/detail/" + this.$route.query.equFaultId;
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.equipmentInfoVal = res.value;
            this.alarmInfoVal = res.value.areaFaultInfoList[0];
            this.processing = res.value.areaFaultInfoList[0];
            this.reasonHandle(res.value.areaFaultInfoList);
            Object.assign(this.processing, this.equipmentInfoVal);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    TitleModule,
    infoTable,
  },
  created() {
    if (sessionStorage.juese) {
      this.formInline = JSON.parse(sessionStorage.juese);
      sessionStorage.juese = "";
    }
  },
  mounted() {
    this.queryDetail();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
	height: 35px;
.content
	background: #FFFFFF;
	overflow: hidden;
	border: 1px solid #C0CCDA;
	border-radius: 4px;
	padding: 20px;
</style>
<style scoped>
.el-row {
  margin: 10px 0;
  text-align: left;
}
.reason {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
